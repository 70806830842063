import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "../style/sidebar.scss";
import logo from "../images/logo.png";
import {
  faHome,
  faClipboardList,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NAME_KEY } from "../constants/key.const";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toggle from "./Toggle";
import { L } from "../langauge/english.lang";
import { useAuth } from "../contexts/AuthContext";
import ConfirmationModal from "./ConfirmationModal";
import { deleteUser } from "../services/userService";
import { logout } from "../services/authService";

const SideBar = ({ privacyMode, setPrivacyMode, page, setPage }) => {
  const navigate = useNavigate();
  // const [privacyMode, setPrivacyMode] = useState(true);
  const { logoutUser } = useAuth();
  const name = sessionStorage.getItem(NAME_KEY);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSuperUser = sessionStorage.getItem("SUPERUSER") === "true";

  const getGreeting = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)

    if (currentHour >= 3 && currentHour < 12) {
      return L.GREETING_MORNING;
    } else if (currentHour >= 12 && currentHour < 18) {
      return L.GREETING_AFTER_NOON;
    } else {
      return L.GREETING_EVENING;
    }
  };

  const handleDeleteAccount = () => {
    setShowModal(true);
    handleClose();
  };

  // const links = [
  //   { path: "/home", label: L.LINK.START, icon : faHome },
  //   // { path: "/agenda", label: "Agenda" },
  //   { path: "/overview", label: L.LINK.OVERVIEW, icon : faClipboardList },
  //   // { path: "/admin", label : L.LINK.ADMIN, icon : faUser },
  //   { path: "/admin", label: L.LINK.ADMIN, icon: faUser, superUserOnly: true },
  // ];

  let links = [
    { path: "/home", label: L.LINK.START, icon: faHome },
    { path: "/overview", label: L.LINK.OVERVIEW, icon: faClipboardList },
  ];

  if (isSuperUser) {
    links.push({ path: "/admin", label: L.LINK.ADMIN, icon: faUser });
  }
  return (
    <>
      <div className="sidebar">
        <div className="side">
          <div className="top">
            <div className="logo">
              <img src={logo}></img>
            </div>

            <div className="links">
              {links.map((link, i) => (
                <NavLink
                  className="link"
                  to={link.path}
                  onClick={(e) => setPage(link.label)}
                >
                  <FontAwesomeIcon
                    icon={link.icon}
                    size="1.1x"
                    className="icon"
                  />
                  {link.label}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="bottom">
            <Toggle
              label={L.PRIVACY_MODE}
              toggled={privacyMode}
              onClick={(e) => setPrivacyMode(e)}
            />
          </div>
        </div>

        <div className="container-sidebar">
          <div className="header">
            <div className="page"> {page} </div>
            <div className="right-section">
              {isSuperUser && page === L.LINK.ADMIN && (
                <div className="admin-buttons">
                  <button onClick={() => navigate("/add-user")}>
                    Add User
                  </button>
                  <button onClick={() => navigate("/add-appointment")}>
                    Add Appointment
                  </button>
                </div>
              )}

              <div className="profile" onClick={handleClick}>
                <div className="username">
                  {" "}
                  {getGreeting()}, {name}{" "}
                </div>

                <div className="profile-icon">
                  <PermIdentityOutlinedIcon />
                </div>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <MenuItem onClick={handleClose}> <NavLink to="/profile"> <PermIdentityOutlinedIcon/> Profile</NavLink></MenuItem> */}
                <MenuItem
                  onClick={logoutUser}
                  style={{ background: "#ffffff" }}
                >
                  {" "}
                  <LogoutOutlinedIcon
                    style={{ color: "#3667E9", marginRight: "8px" }}
                  />{" "}
                  <span style={{ color: "#0B1423" }}>{L.LOGOUT}</span>{" "}
                </MenuItem>
                <MenuItem
                  onClick={handleDeleteAccount}
                  style={{ background: "#ffffff" }}
                >
                  <span style={{ color: "#0B1423" }}>Delete Account</span>{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/change-password")}
                  style={{ background: "#ffffff" }}
                >
                  <span style={{ color: "#0B1423" }}>Change Password</span>{" "}
                </MenuItem>
              </Menu>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
      <ConfirmationModal
        text="Are you sure want to delete your account ?"
        show={showModal}
        title="Alert"
        handleClose={(confirmed) => {
          setShowModal(false);
          if (confirmed) {
            deleteUser();
            logout();
            navigate("/sign-in");
          }
        }}
      />
    </>
  );
};

export default SideBar;
