import { Outlet, useLocation } from "react-router-dom";
import "../style/home.scss";
import "../style/walkthrough.scss";
import { L } from "../langauge/english.lang";
// import Step2 from "../component/Step2";

const WalkThrough = ({ privacyMode, activeStep }) => {
  const apt = useLocation().state.apt;

  const steps = [
    { id: 1, label: L.RECORD },
    // {id : 99, label : L.REPLAYRECORDING},
    { id: 2, label: L.REVIEW_NOTE },
    { id: 3, label: L.WRAP_UP },
  ];

  const getShortName = (name) => {
    return name.split(" ").reduce((ac, val) => {
      ac = ac + val[0];
    }, "");
  };

  return (
    <div className="start-main">
      <div className="left">
        <div className="client-name">
          <div className="head"> {L.CURRENT_CLIENT} </div>
          <div className="name">
            {privacyMode ? getShortName(apt.client_name) : apt.client_name}
          </div>
        </div>

        <div className="walkthrough">
          <div className="head"> {L.WALKTHROUGH} </div>
          <div className="steps">
            {steps.map((s) => (
              <div className={`step ${activeStep == s.id ? "active" : ""}`}>
                {" "}
                {s.label}{" "}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="right-1">
        <Outlet />
      </div>
    </div>
  );
};

export default WalkThrough;
