import React, { useEffect, useState } from "react";
import axios from "axios";
import { API, getAuthHeader } from "../constants/api.const";
import "../style/agenda.scss";
import { L } from "../langauge/english.lang";
import StatusDisplay from "../component/StatusDisplay";
import { useNavigate } from "react-router-dom";

const OverviewPage = ({ privacyMode }) => {
  const [agenda, setAgenda] = useState([]);
  const navigate = useNavigate();

  const [allData, setAllData] = useState(true);

  const formatDate = (dateString, options) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-In", options).format(date);
  };

  const handleClick = (recording) => {
    console.log(recording);
    navigate("/recording-details", {
      state: { recording: recording, isAdmin: false },
    });
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    axios
      .get(API.GET_RECORDINGS, {
        headers: getAuthHeader(),
      })
      .then((res) => {
        if (res && res.data) setAgenda(res.data.data);
      })
      .catch((e) => console.log(e));
  }, [selectedDate]);
  return (
    <div className="agenda admin-component overview-component">
      <div className="left content">
        <div className="head overview-head">
          <div
            className={`overview-item ${allData ? "active" : ""}`}
            onClick={(e) => setAllData(true)}
          >
            {L.ALL_CONSULTATION}
          </div>
          <div
            className={`overview-item ${allData ? "" : "active"}`}
            onClick={(e) => setAllData(false)}
          >
            {L.PENDING_CONSULTATION}
          </div>
          {/* <h3 className='date'> {formatDate(selectedDate.toISOString(), {weekday : 'long' })}, {formatDate(selectedDate.toISOString(), {dateStyle : 'medium' })}  </h3> */}
        </div>
        {agenda.length > 0 ? (
          <div className="list ">
            {agenda
              .filter((cons) => !cons.is_reviewed || allData)
              .map((item) => (
                <div className="item" onClick={(e) => handleClick(item)}>
                  <div className="seg2">
                    <div className="top">
                      <div>
                        <div className="name">
                          {privacyMode
                            ? item.appointment?.patient?.first_name[0] +
                              (item.appointment?.patient?.last_name
                                ? item.appointment?.patient?.last_name[0]
                                : "")
                            : item.appointment?.patient?.first_name +
                              " " +
                              item.appointment?.patient?.last_name}
                        </div>
                        <div className="time">
                          {formatDate(item.appointment?.appointment_time, {
                            dateStyle: "medium",
                          })}
                        </div>{" "}
                        <div className="time">
                          {formatDate(item.appointment?.appointment_time, {
                            timeStyle: "short",
                          })}
                        </div>
                      </div>
                      <StatusDisplay
                        className="icon"
                        isReviewed={item.is_reviewed}
                      />
                    </div>
                    <div className="desc">{item.description}</div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="norecord"> {L.NO_CONSULTATION} </div>
        )}
      </div>

      {/* <div className="right">
      <LocalizationProvider dateAdapter={AdapterDayjs} className='cal'>
      <DateCalendar onChange={e=>setSelectedDate(e)} />
    </LocalizationProvider>
      </div> */}
    </div>
  );
};

export default OverviewPage;
