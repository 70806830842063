const ENGLISH_LANGAUGE = {
  ALL_CONSULTATION: "All Consultations",
  CLIENT: "Client",
  CLIENT_NAME: "Client Name",
  COMPLETED_RECORDING: "Completed Recording",
  CONTINUE_WT_MS: "Continue with Microsoft",
  CURRENT_CLIENT: "Current Client",
  GREETING_MORNING: "Good Morning",
  GREETING_AFTER_NOON: "Good After Noon",
  GREETING_EVENING: "Good Evening",
  LOGOUT: "Logout",
  NO_CLIENT: "Seeing a client not in your calender? Create a note :",
  NO_CONSULTATION: "There are no any consultation for today",
  NOTE: "Note",
  FINAL_NOTE: "Final Note",
  PENDING_CONSULTATION: "Pending Consultation",
  PRIVACY_MODE: "Privacy Mode",
  READY_FOR_EHR: "Ready for EHR",
  RECORD: "Record",
  RECORDING: "Recording",
  RESUME_RECORDING: "Resume recording",
  REVIEW_NOTE: "Review Note",
  SAVING: "Opslaan...",
  SIGN_IN: "Sign In",
  START_RECORDING: "Start Recording",
  TO_REVIEW: "To Review",
  TODAY_CONSULTATION: "Your Consultations Today",
  UNPLANNED_CONTACT: "Unplanned Contact",
  WALKTHROUGH: "Walkthrough",
  WRAP_UP: "Wrap Up",
  REPLAYRECORDING: "Replay Recoding",

  MSG: {
    INVALID_EMAIL_PASSWORD: "Invalid email or password",
    CONVERSATION_PROCESSED: "Your conversation is being processed for summary.",
    CONVERSATION_SAVED:
      "Recording is saved and being processed. You can start a new recording",
    THANKS_FOR_PATIENCE:
      "Thanks for your patience. This might take a little long.",
    RECORDING_DELETED: "Recording deleted successfully",
  },

  LINK: {
    START: "Start",
    OVERVIEW: "Overview",
    ADMIN: "Admin",
  },

  DELETE_MODAL: {
    TITLE: "Delete Recording",
    TEXT: "Are you sure you want to delete the recording?",
    YES: "Yes",
  },
  SAVE_MODAL: {
    TITLE: "Save recording",
    TEXT: "Are you sure you want to save this recording?",
    YES: "Yes",
    NO: "Cancel",
  },
  SAVE: "Save",
};

const DUTCH_LANGAUGE = {
  ALL_CONSULTATION: "Alle afspraken",
  CLIENT: "Cliënt",
  CLIENT_NAME: "Naam van de Cliënt",
  COMPLETED_RECORDING: "Opname voltoid",
  // CONTINUE_WT_MS : 'Doorgaan met Microsoft',
  CONTINUE_WT_MS: "Continue with Microsoft",
  CURRENT_CLIENT: "Cliënt",
  GREETING_MORNING: "Goedemorgen",
  GREETING_AFTER_NOON: "Goedemiddag",
  GREETING_EVENING: "Goedenavond",
  LOGOUT: "Uitloggen",
  NO_CLIENT:
    "Spraak je een cliënt die niet in je agenda staat? Maak een gespreksverslag:",
  NO_CONSULTATION: "Er zijn geen consultaties voor vandaag",
  NOTE: "Gespreksverslag",
  FINAL_NOTE: "Definitief gespreksverslag",
  PENDING_CONSULTATION: "Nog te beoordelen",
  PRIVACY_MODE: "Privacy stand",
  READY_FOR_EHR: "Klaar voor EPD",
  RECORD: "Opnemen",
  RECORDING: "Opname",
  RESUME_RECORDING: "Hervat de opname",
  REVIEW_NOTE: "Gespreksverslag beoordelen",
  SAVING: "Saving...",
  SIGN_IN: "Inloggen",
  START_RECORDING: "Start opname",
  TO_REVIEW: "Nog te beoordelen",
  TODAY_CONSULTATION: "Afspraken vandaag",
  UNPLANNED_CONTACT: "Ongeplande contact",
  WALKTHROUGH: "De werkwijze",
  WRAP_UP: "Afronden",
  REPLAYRECORDING: "Opname afspelen",

  MSG: {
    INVALID_EMAIL_PASSWORD: "Ongeldig e-mailadres of wachtwoord",
    CONVERSATION_PROCESSED: "Uw gesprek wordt verwerkt voor samenvatting.",
    CONVERSATION_SAVED:
      "De opname wordt opgeslagen en verwerkt. Je kunt in een nieuwe opname starten.",
    THANKS_FOR_PATIENCE: "Bedankt voor uw geduld. Dit kan even duren.",
    RECORDING_DELETED: "Opname succesvol verwijderd",
  },

  LINK: {
    START: "Start",
    OVERVIEW: "Overzicht",
    ADMIN: "Administratie",
  },

  DELETE_MODAL: {
    TITLE: "Opname verwijderen",
    TEXT: "Ben je zeker dat je deze opname wilt verwijderen?",
    YES: "Ja",
    NO: "Annuleren",
  },
  SAVE_MODAL: {
    TITLE: "Opname opslaan",
    TEXT: "Ben je zeker dat je deze opname wilt opslaan?",
    YES: "Ja",
    NO: "Annuleren",
  },
  SAVE: "opslaan",
};


export const L =DUTCH_LANGAUGE 

